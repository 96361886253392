import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { CheckIcon } from "@heroicons/react/outline"

const RobotPage = () => {
  const features = [
    {
      name: "本地化执行",
      description: "本地化运行，隐私数据保存在本地，安全可靠",
    },
    {
      name: "计划作业",
      description: "设置好定时任务，无人值守，24×7自动运行",
    },
    {
      name: "执行实时反馈",
      description: "实时查看任务执行情况，异常实时报警",
    },
    {
      name: "场景触发",
      description: "多种方式启动机器人，无缝融入各种业务场景",
    },
    {
      name: "多机器人协作",
      description: "多机器人同时工作，并行处理任务，处理速度倍增",
    },
    {
      name: "异常处理",
      description: "机器人异常自动重试、异常通知，提升稳定性",
    },
    {
      name: "人机交互",
      description: "遇到机器人无法处理的情况，自动弹窗让业务人员协作处理",
    },
    {
      name: "支持多系统",
      description: "支持Windows、信创系统运行，支持公有云、私有云",
    },
  ]

  return (
    <Layout>
      <Seo title="IBRPA运行器" />
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-indigo-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">IBRPA运行器</span>
                <span className="block"></span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-indigo-200">
                无人值守，24×7自动运行RPA任务
              </p>
              <Link
                to="/trial"
                className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50"
              >
                免费试用
              </Link>
            </div>
          </div>
          <div className="py-8 lg:py-12">
            <StaticImage
              className="rounded-md object-cover object-left-top"
              src="../../images/运行器.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">功能特点</h2>
          <p className="mt-4 text-lg text-gray-500"></p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {features.map(feature => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon
                  className="absolute h-6 w-6 text-green-500"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </Layout>
  )
}

export default RobotPage
